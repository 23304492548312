export default {
  address: '221新北市汐止區康寧街457號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.9827148898416!2d121.62923131549852!3d25.068574983956196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x345d534c7e98316d%3A0xa614b98d1cda13f8!2zMjIx5paw5YyX5biC5rGQ5q2i5Y2A5bq35a-n6KGXNDU36Jmf!5e0!3m2!1szh-TW!2stw!4v1562048804690!5m2!1szh-TW!2stw',
  phone: '02-2692-1155',
  fbLink: 'https://www.facebook.com/%E6%B1%90%E6%AD%A2%E6%9D%B1%E5%9F%8E%E5%A4%A7%E5%A2%83-584895791902170/',
  fbMessage: 'https://m.me/584895791902170',
  googleLink: 'https://goo.gl/maps/cK2KFFP5boSLGX338',
  caseName: '東城大境',

  houseInfos: [
    ['投資興建', '合康建設股份有限公司'],
    ['營造公司', '洛城營造'],
    ['建築設計', '閤康聯合建築師事務所'],
    ['景觀設計', '六國景觀-蘇瑞泉大師'],
    ['公設設計', '研舍設計-林建宏'],
    ['企劃銷售', '新高創廣告有限公司'],
    ['接待中心', '新北市汐止區康寧街457號'],
  ],

  gtmCode: ['K8PX6LK'], // 可放置多個
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}
